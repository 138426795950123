import React, { useState } from 'react';
import { rem } from 'polished';
import { css, styled } from 'styles';
import { KeyBubble, LaptopBubble } from 'icons';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Error } from '@unmind/design-system-components-web/dist/components/Form/Error';
import {
  getAuthErrorMessage,
  ErrorTranslationKey,
} from 'LoggedOut/Login/AuthError';
import {
  AuthWrapper,
  AuthWrapperBlock,
  AuthWrapperContent,
  AuthWrapperTitle,
} from '../AuthWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import AuthMethodCard from '../AuthMethodCard';
import PrimaryButton from '../../Shared/PrimaryButton';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import RoutePath from '../../App/RoutePath';
import { small, medium } from '../../utils';

export const AuthMethodWrapper = styled(AuthWrapperContent)`
  padding-top: 0;
  gap: 24px;

  flex: 0.1 0 auto;

  ${small(css`
    padding: ${rem(24)} 0;
  `)}

  ${medium(css`
    padding: ${rem(24)} 0;
  `)}
`;

const ErrorContainer = styled.div`
  margin-top: ${rem(16)};
`;

export const AuthMethod = () => {
  const [selectedAuthMethod, setSelectedAuthMethod] =
    useState<AuthTypeEnum | null>(null);

  const subdomain = getSubdomainFromUrl();
  const { groupName, loading: subdomainInfoLoading } = useSubdomainInfo({
    subdomain,
  });
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { errorCode, errorMessage } = getAuthErrorMessage(urlSearchParams);

  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const history = useHistory();

  const location = useLocation();
  // we pass in the 'sign up' query param if the user is coming from the sign up page
  const redirectLocation = new URLSearchParams(location.search).get('redirect');
  const isSignUpFlow =
    redirectLocation && redirectLocation.indexOf('signup') > 0;

  const navigateWithQueryParams = (route: RoutePath) => {
    history.push(`${route}${location.search ?? ''}`);
  };

  const toggleAuthMethod = (authMethod: AuthTypeEnum) => {
    setSelectedAuthMethod(authMethod);
  };

  if (subdomainInfoLoading) {
    return <LoadingIndicator />;
  }

  const onSubmit = () => {
    if (selectedAuthMethod === AuthTypeEnum.AUTH0_SSO) {
      // we don't need to adjust for the sign up flow as the SSO page is the same for both
      navigateWithQueryParams(RoutePath.LoginWithSSO);
    } else if (selectedAuthMethod === AuthTypeEnum.LEGACY_CREDENTIALS) {
      if (isSignUpFlow) {
        history.push(RoutePath.SignUp);
      } else {
        navigateWithQueryParams(RoutePath.LoginWithCredentials);
      }
    }
  };

  return (
    <>
      <AuthWrapper subdomain={groupName}>
        <AuthWrapperBlock>
          <AuthWrapperTitle>{translate('auth_method.title')}</AuthWrapperTitle>
          <AuthMethodWrapper role="radiogroup">
            <AuthMethodCard
              authMethod={AuthTypeEnum.AUTH0_SSO}
              label="sso"
              ariaLabel={translate('auth_method.sso_card.a11y_label')}
              Icon={KeyBubble}
              heading={translate('auth_method.sso_card.heading')}
              subheading={translate('auth_method.sso_card.subheading')}
              toggleAuthMethod={toggleAuthMethod}
              selected={selectedAuthMethod === AuthTypeEnum.AUTH0_SSO}
            />
            <AuthMethodCard
              authMethod={AuthTypeEnum.LEGACY_CREDENTIALS}
              label="credentials"
              ariaLabel={translate('auth_method.credentials_card.a11y_label')}
              Icon={LaptopBubble}
              heading={translate('auth_method.credentials_card.heading')}
              subheading={translate('auth_method.credentials_card.subheading')}
              toggleAuthMethod={toggleAuthMethod}
              selected={selectedAuthMethod === AuthTypeEnum.LEGACY_CREDENTIALS}
            />
            <PrimaryButton
              label={translate('auth_method.submit_button.label')}
              aria-label={translate('auth_method.submit_button.a11y_label')}
              onClick={onSubmit}
              disabled={!selectedAuthMethod}
              aria-disabled={!selectedAuthMethod}
              data-testid="auth-method-continue"
            />
            {errorCode && (
              <ErrorContainer>
                <Error
                  errorText={translate(errorMessage as ErrorTranslationKey)}
                  showIcon
                />
              </ErrorContainer>
            )}
          </AuthMethodWrapper>
        </AuthWrapperBlock>
      </AuthWrapper>
    </>
  );
};

export default AuthMethod;
