import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Fragment } from 'react';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { LDContext, LDEvaluationDetail } from 'launchdarkly-js-sdk-common';
import { datadogRum } from '@datadog/browser-rum';
import getSubdomainFromUrl from '../utils/getSubdomainFromUrl';
import { LAUNCH_DARKLY_USER_KEY } from './withFeatureFlagUserContext';

const datadogEnabled = process.env.REACT_APP_DATADOG_ENABLED === 'true';

const createFeatureFlagProvider = ({ key }: { key: string }) => {
  const subdomain = getSubdomainFromUrl();
  const teamsContext = getMSTeamsUserContext();
  const teamsTenantId = isMSTeams() ? teamsContext?.user?.tenant?.id : null;
  let user = JSON.parse(
    localStorage.getItem(LAUNCH_DARKLY_USER_KEY) || 'null',
  ) as LDContext;

  const userOrgInfo = {
    subdomain,
    ...(teamsTenantId && { tenantId: teamsTenantId }),
  };

  // ensure backwards compatibility for user context
  // by moving custom attributes to top level object
  if (user && user.custom) {
    user = {
      ...user,
      ...userOrgInfo,
      ...user.custom,
    };

    delete user.custom;

    // store the updated user in local storage
    localStorage.setItem(LAUNCH_DARKLY_USER_KEY, JSON.stringify(user));
  }

  const FeatureFlagProvider = withLDProvider({
    clientSideID: key,
    options: {
      ...(datadogEnabled && {
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (ldKey: string, detail: LDEvaluationDetail) => {
              datadogRum.addFeatureFlagEvaluation(
                ldKey,
                JSON.stringify(detail),
              );
            },
          },
        ],
      }),
      logger:
        process.env.REACT_APP_ENVIRONMENT === 'development'
          ? basicLogger({ level: 'debug' })
          : undefined,
      evaluationReasons: true,
    },
    context: {
      kind: 'user',
      anonymous: true,
      ...userOrgInfo,
      ...(user || {}),
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(({ children }) => <Fragment>{children}</Fragment>);

  return FeatureFlagProvider;
};

export default createFeatureFlagProvider;
