import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';
import RoutePath from '../../App/RoutePath';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import {
  AuthWrapper,
  NewAuthWrapperBlock,
  AuthWrapperBlockContainer,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
} from '../AuthWrapper';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

function ResetPasswordEmailConfirmation(props: RouteChildrenProps) {
  const { email } = props.location.state;
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const subdomain = getSubdomainFromUrl();
  const { groupName } = useSubdomainInfo({
    subdomain,
  });

  return (
    <AuthWrapper
      subdomain={groupName}
      newDesign
      showCreateAccountButton={false}
    >
      <NewAuthWrapperBlock data-testid="v2-email-confirmation-screen">
        <AuthWrapperBlockContainer>
          <AuthWrapperTitle>
            {translate('login.reset_password_email_confirmation.heading')}
          </AuthWrapperTitle>
          <AuthWrapperSubtitle data-testid="email-confirmation-subtitle">
            {Trans({
              t: translate,
              i18nKey: 'login.reset_password_email_confirmation.subtitle',
              values: { email },
              components: { email_address: <EmailAddress /> },
            })}
          </AuthWrapperSubtitle>
          <AuthWrapperContent>
            <EmailConfirmationHelperText>
              {Trans({
                t: translate,
                i18nKey: 'login.reset_password_email_confirmation.helper_text',
                components: {
                  confirmation_link: <BoldLink to={RoutePath.ForgotPassword} />,
                },
              })}
            </EmailConfirmationHelperText>
            <EmailConfirmationFooter userEmailAddress={email} />
          </AuthWrapperContent>
        </AuthWrapperBlockContainer>
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
}

export default ResetPasswordEmailConfirmation;
