import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { compose } from 'recompose';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { EmailOnlyFormFields } from '../../Shared/Form/Formik';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import {
  NewAuthWrapperBlock,
  AuthWrapperTitle,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapper,
} from '../AuthWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import EmailInputForm from '../SignUp/Forms/EmailInputForm';
import { useMagicLogin } from './useMagicLogin';

export type MagicLinkProps = SubdomainFromUrlProps;

type FormFields = EmailOnlyFormFields;

export function MagicLogin(props: MagicLinkProps) {
  const { subdomain } = props;
  const { groupName, groupId } = useSubdomainInfo({
    subdomain,
  });
  const { requestMagicLogin, loading } = useMagicLogin();
  const { t: sharedTranslate } = useTranslation('shared');
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'login.forms.magic_login',
  });
  const formikRef = useRef<Formik<FormFields>>(null);

  const history = useHistory();
  const [error, setError] = useState<string | undefined>();

  const errorMessage = sharedTranslate('errors.messages.something_wrong');

  async function onSubmit({ emailInput }: FormFields) {
    if (!groupId || !emailInput) {
      setError(errorMessage);

      return;
    }

    setError(undefined);

    const req = await requestMagicLogin({
      email: emailInput,
      groupId,
    });

    if (req.success) {
      tracking.track('magic-link-sent', { Subdomain: subdomain });
      history.push(RoutePath.MagicLoginConfirmation, { email: emailInput });

      return;
    }

    if (req.error) {
      setError(errorMessage);
    }
  }

  const emailLabel = translate('email_label');
  const emailPlaceholder = translate('email_placeholder');
  const submitLabel = translate('submit_button.label');

  return (
    <AuthWrapper
      subdomain={groupName}
      newDesign
      showCreateAccountButton={false}
    >
      <NewAuthWrapperBlock>
        <AuthWrapperTitle data-testid="title">
          {translate('heading')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="subtitle">
          {translate('subtitle')}
        </AuthWrapperSubtitle>

        <AuthWrapperContent>
          <EmailInputForm
            formikRef={formikRef}
            inputLabel={emailLabel}
            placeholder={emailPlaceholder}
            submitLabel={submitLabel}
            onSubmit={onSubmit}
            loading={loading}
            setError={setError}
            error={error}
          ></EmailInputForm>
        </AuthWrapperContent>
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
}

export default compose<MagicLinkProps, RouteComponentProps>(
  withSubdomainFromUrl,
)(MagicLogin);
