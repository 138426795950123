import { useFlags } from 'launchdarkly-react-client-sdk';

export type FeatureFlag = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS];

export const FEATURE_FLAGS = {
  AB_TEST_SERIES_FEATURE_NAME_JULY21_IS_NEW:
    'ab-test-series-feature-name-july21-is-new',
  ADMIN_DASHBOARDS_ENABLE_TIME_FILTERING:
    'admin-dashboards-enable-time-filtering',
  ADMIN_DASHBOARDS_SHOW_FILTERS: 'admin-dashboards-show-filters',
  DISNEY_CHATBOT: 'disney-chatbot',
  HIDE_SERIES_BANNER: 'hide-series-banner',
  NEW_COURSES_UI: 'new-courses-ui',
  PLUS_ONE: 'plus-one',
  RECOMMENDED_TOOLS_BANNER: 'recommended-tools-banner',
  SHOULD_SHOW_SEARCH_WEB: 'should-show-search-web',
  SHOW_ACTIVE_USER_TRACKING_CHANGE_NOTIFICATION:
    'show-active-user-tracking-change-notification',
  SHOW_ALTERNATIVE_START_SCREEN:
    'workplace-engagement-should-show-alternative-homescreen',
  SHOW_EXPLORE_WEB: 'show-explore-web',
  SHOW_INTEGRATIONS_TAB_WEB: 'show-integrations-tab-on-web',
  SHOW_MANAGER_CHEAT_SHEET_WEB: 'show-manager-cheat-sheet-web',
  SHOULD_SHOW_MANAGER_EXPERIENCE_WEB: 'should-show-manager-experience-web',
  SHOW_PERSONALISED_RECOMMENDATIONS_WEB:
    'show-personalised-recommendations-web',
  SHOW_WORKPLACE_INSIGHTS_NAV_OPTION: 'show-workplace-insights-nav-option',
  SHOW_WELLBEING_SCORE_UPGRADE_BANNER: 'show-wellbeing-score-upgrade-banner',
  SHOW_TALK_ONBOARDING_MODAL: 'show-talk-onboarding-modal',
  TALK_ENABLE_CANCEL_EVENT: 'talk-web-app-enable-cancel-event-nov-23',
  TALK_ENABLE_MATCHING: 'talk-web-app-enable-matching-apr-24',
  TALK_ENABLE_OUTCOME_MEASURES: 'talk-web-app-enable-outcome-measures-nov-23',
  TALK_ENABLE_IMPROVE_HELP_ACCESS_WEB: 'talk-web-app-help-access',
  TEAM_GROWTH_NEW_SEND_INVITE_FLOW: 'team-growth-new-send-invite-flow',
  COMPASS: 'workplace-engagement-compass-enabled',
  COMPASS_SHOW_NOVA_RECOMMENDATION: 'compass-show-nova-recommendation',
  USE_REBRAND_THEME: 'use-rebrand-theme',
  SHOW_INVITE_LOVED_ONE_TAB_IN_MSTEAMS: 'show-invite-loved-one-tab-in-msteams',
  ENABLE_CHATBOT: 'show-ai-chatbot',
  DATA_SCIENCE_API_READY: 'manager-engagement-data-science-api-ready',
  DATADOG_INIT_OPTIONS: 'datadog-init-options',
  DATADOG_START_SESSION_REPLAY_RECORDING:
    'datadog-start-session-replay-recording',
  SHOW_NOTIFICATION_PREFERENCE_CENTRE:
    'workplace-engagement-show-preference-centre',
  SHOW_EMAIL_NOTIFICATIONS: 'workplace-engagement-preference-center-v2',
  TEAMS_HOME_PAGE_EXPERIMENT: 'teams-home-page-experiment',
  NEW_START_ROUTE_ENABLED: 'enterprise-readiness-show-new-start-route',
} as const;

function useFeatureFlag<T = boolean>(flag: FeatureFlag): T {
  const allFlags = useFlags();

  return allFlags[flag];
}

export default useFeatureFlag;
