import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from 'App/RoutePath';
import BackButton from 'Shared/Button/BackButton';
import SecondaryButton from 'Shared/SecondaryButton';
import { useHistory } from 'react-router-dom';
import { tracking } from 'App/Tracking';
import { getPrivacyPolicyLink } from 'utils';
import { getHelpCenterLink } from 'utils/getHelpCenterLink';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import {
  AuthContent,
  AuthWrapperFooter,
  AuthWrapperGrid,
  SubdomainHeader,
  Container,
  LinksContainer,
  LockIcon,
  HelpIcon,
  Link,
  LinkText,
  NewAuthContent,
} from './AuthWrapper.styled';
import { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

export { AuthWrapperHelpCentre } from './AuthWrapperHelpCentre';

interface AuthWrapperProps {
  children: ReactNode;
  subdomain?: string;
  progressBar?: ReactNode;
  showFooter?: boolean;
  newDesign?: boolean;
  showCreateAccountButton?: boolean;
}

export const AuthWrapper = ({
  children,
  subdomain,
  progressBar,
  showFooter = true,
  newDesign = false,
  showCreateAccountButton = true,
}: AuthWrapperProps) => {
  const { t: translate } = useTranslation('logged_out');
  const history = useHistory();
  const { group } = useSubdomainInfo({ subdomain: subdomain ?? '' }) ?? {};

  const getSignUpScreen = (authType: AuthTypeEnum) => {
    switch (authType) {
      case AuthTypeEnum.LEGACY_CREDENTIALS:
        return RoutePath.SignUp;
      case AuthTypeEnum.AUTH0_SSO:
        return RoutePath.LoginWithSSO;
      default:
        return RoutePath.AuthMethod;
    }
  };

  return (
    <AuthWrapperGrid>
      {newDesign ? (
        <NewAuthContent showFooter={showFooter}>
          <Container>
            {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
            {isMSTeams() && (
              <BackButton linkToDefault={RoutePath.StartSplitScreen} />
            )}
          </Container>
          {progressBar}
          {children}
          {showFooter && (
            <AuthWrapperFooter>
              {showCreateAccountButton && (
                <SecondaryButton
                  label={translate('footer.create_account_button.label')}
                  onClick={() => {
                    history.push(
                      `${
                        subdomain && group?.authType
                          ? getSignUpScreen(group.authType)
                          : RoutePath.SignInToYourOrganisation
                      }?redirect=/signup`,
                    );
                    tracking.track('create-account-clicked', { subdomain });
                  }}
                />
              )}
              <LinksContainer>
                <Link data-testid="help-link" href={getHelpCenterLink()}>
                  <HelpIcon />
                  <LinkText>{translate('footer.help.label')}</LinkText>
                </Link>
                <Link data-testid="privacy-link" href={getPrivacyPolicyLink()}>
                  <LockIcon />
                  <LinkText>
                    {translate('footer.privacy_policy.label')}
                  </LinkText>
                </Link>
              </LinksContainer>
            </AuthWrapperFooter>
          )}
        </NewAuthContent>
      ) : (
        <AuthContent>
          <Container>
            {/* MS teams users can't tab backwards so they need to be shown a clickable button */}
            {isMSTeams() && (
              <BackButton linkToDefault={RoutePath.StartSplitScreen} />
            )}
            {subdomain && (
              <SubdomainHeader data-testid="business-pill">
                {subdomain}
              </SubdomainHeader>
            )}
          </Container>
          {progressBar}
          {children}
          {showFooter && (
            <AuthWrapperFooter>
              <AuthWrapperHelpCentre />
            </AuthWrapperFooter>
          )}
        </AuthContent>
      )}
    </AuthWrapperGrid>
  );
};
