import { Formik } from 'formik';
import { rem } from 'polished';
import queryString from 'query-string';
import * as moment from 'moment-timezone';
import React, { ReactNode, useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import type { ApolloClient } from '@apollo/client';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Wand } from 'icons';
import { Helmet } from 'react-helmet-async';
import { isMSTeams } from 'utils/MSTeams';
import {
  TextInput,
  SecureTextInput,
  Loader,
  Error,
  small,
} from '@unmind/design-system-components-web';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import { validateEmail } from '../../Shared/Form/Formik';
import {
  NewAuthWrapperBlock,
  AuthWrapper,
  AuthWrapperTitle,
} from '../AuthWrapper';
import {
  StyledForm,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import { configureSentryUserScope } from '../../App/logging';
import withFeatureFlagUserContext from '../../flags/withFeatureFlagUserContext';
import { WithFeatureFlagUserContextProps } from '../../flags/types';
import { useGroupId } from '../useGroupId';
import withUpdateUser, {
  WithUpdateUserProps,
} from '../../Account/withUpdateUser';
import { AuthPayload, AuthStatus, LoginAction } from '../../App/Auth';
import { Errors } from '../../Shared/Errors';
import {
  withConfirmAndAuthUser,
  WithConfirmAndAuthUserProps,
} from '../withConfirmAndAuthUser';
import { VIRGIN_PULSE_PARTNER_NAME } from '../VirginPulse/consts';
import { identifyBrazeUserAndMergeProfiles } from '../../App/braze/identifyBrazeUser';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import SecondaryButton from '../../Shared/SecondaryButton';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import {
  withLoginWithToken,
  WithLoginWithTokenProps,
} from './withLoginWithToken';
import withLogin, { LoginVariables, WithLoginChildProps } from './withLogin';

const WandIcon = styled(Wand)`
  width: ${rem(17)};
  height: ${rem(17)};
  margin-right: ${rem(8)};
  flex-shrink: 0;
`;

const MagicLinkButton = styled(SecondaryButton)`
  width: 100%;
`;

const StyledSSOButton = styled(SecondaryButton)`
  width: 100%;
  margin-top: ${rem(24)};
`;

const InputContainer = styled.div`
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};

  ${small(css`
    margin-top: ${rem(24)};
    margin-bottom: ${rem(16)};
  `)}
`;

const ForgotPasswordLink = styled(Link)`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(48)};
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const PasswordLinkContainer = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(24)};

  ${small(css`
    margin-bottom: ${rem(48)};
  `)}
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MagicLinkButtonContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface HandleAuthResponseProps {
  response: AuthPayload;
  isFirstLogin: boolean;
  loginAction: LoginAction;
}

interface FormValues {
  emailAddress: string;
  password: string;
}

export interface LoginViaUsernamePasswordProps
  extends RouteComponentProps,
    WithFeatureFlagUserContextProps,
    WithLoginChildProps,
    WithLoginWithTokenProps,
    WithConfirmAndAuthUserProps,
    WithUpdateUserProps {
  client: ApolloClient<any>;
}

export function LoginViaUsernamePassword(props: LoginViaUsernamePasswordProps) {
  const subdomain = getSubdomainFromUrl();

  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const {
    groupName,
    group,
    ssoProviderName,
    loading: subdomainInfoLoading,
  } = useSubdomainInfo({
    subdomain,
  });
  const { groupId } = useGroupId({ subdomain });

  const [loginRedirect, setLoginRedirect] = useState<string | null>(null);
  const [loginError, setLoginError] = useState('');
  const [magicLoginError, setMagicLoginError] = useState<string | undefined>(
    undefined,
  );
  const [tokenOrRidLoginLoading, setTokenOrRidLoginLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const userTimezone = moment.tz.guess();
  const history = useHistory();

  const partner = group?.isVirginPulseGroup ? VIRGIN_PULSE_PARTNER_NAME : null;

  const handleMagicLoginError = (error: Errors) => {
    switch (error) {
      case Errors.InvalidCredentialsError:
      case Errors.TokenExpiredError:
        setMagicLoginError(translate('login.errors.magic_link_invalid'));
        break;
      case Errors.NotFoundError:
        setLoginError(translate('login.errors.user_not_found'));
        setMagicLoginError(undefined);
        break;
      default:
        setLoginError(translate('login.errors.default_login_error'));
        setMagicLoginError(undefined);
    }
  };

  const identifyLoggedInUser = (
    response: AuthPayload,
    callback: () => void,
  ) => {
    if (response.status === AuthStatus.ERROR) {
      return;
    }

    const { userType, permissions, isLineManager } = response;
    const traits = {
      activationDate: response.activationDate,
      createdAt: response.createdAt,
      uuid: response.id,
      groupId: response.groupId,
      client: response.groupName,
      clientTier: response.clientTier,
      subdomain: response.subdomain,
      praiseDirectoryEnabled: Boolean(Number(response.praiseDirectory.value)),
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      department: response.departmentName,
      departmentId: response.departmentId,
      location: response.locationName,
      locationId: response.locationId,
      userType: userType && userType.name ? userType.name : undefined,
      accessType:
        permissions && permissions.value
          ? permissions.value.toLowerCase()
          : undefined,
      timezone: userTimezone,
      partner,
      identifiedAsLineManager: isLineManager,
    };

    configureSentryUserScope({
      id: response.id,
      subdomain: response.subdomain,
    });

    const userIdForTracking = response.id;

    tracking.identifyUser({
      userId: userIdForTracking,
      traits,
      callback,
    });
  };

  const onSuccessfulLogin = async (
    userId: string,
    userTraits: [string],
    isFirstLogin?: boolean,
  ) => {
    void props.identifyFeatureFlagUser?.({
      userId,
      subdomain,
      userTraits,
    });

    await identifyBrazeUserAndMergeProfiles(props.client, userId);

    await props.updateUser({
      timezone: userTimezone,
    });

    if (!isNil(loginRedirect)) {
      props.history.push(loginRedirect);
    } else {
      props.history.push(RoutePath.Home, { isFirstLogin });
    }
  };

  const handleLoginError = (error: Errors) => {
    switch (error) {
      case Errors.InvalidCredentialsError:
        setLoginError(translate('login.errors.invalid_credentials'));
        break;

      case Errors.AccountLockedError:
        setLoginError(translate('login.errors.account_locked'));
        break;

      default:
        setLoginError(translate('login.errors.default_login_error'));
    }
  };

  const handleAuthResponse = async ({
    response,
    isFirstLogin,
    loginAction,
  }: HandleAuthResponseProps) => {
    setLoading(false);
    switch (response.status) {
      case AuthStatus.DEGRADED: {
        const { token: accessToken, id, userTraits } = response;
        localStorage.setItem('token', accessToken);
        identifyLoggedInUser(response, () => {
          tracking.track('login-successful', {
            subdomain,
            isFirstLogin,
            loginAction,
            type: 'degraded',
          });
        });

        await onSuccessfulLogin(id, userTraits, isFirstLogin);

        break;
      }
      case AuthStatus.SUCCESS: {
        const {
          iris_token: irisToken,
          token: accessToken,
          id,
          userTraits,
        } = response;

        localStorage.setItem('token', accessToken);
        localStorage.setItem('irisToken', irisToken);

        identifyLoggedInUser(response, () => {
          tracking.track('login-successful', {
            subdomain,
            isFirstLogin,
            loginAction,
            type: 'success',
          });
        });
        await onSuccessfulLogin(id, userTraits, isFirstLogin);
        break;
      }
      case AuthStatus.ERROR: {
        handleLoginError(response.error);
        tracking.track('login-failed', { subdomain });
        break;
      }
      default: {
        handleLoginError(Errors.ServerError);
        tracking.track('login-failed', { subdomain });
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const { location, loginWithToken, confirmAndAuthUser } = props;
      const { search } = location;
      const query = queryString.parse(search);
      const { token, redirect, rid } = query;

      if (!(isNil(redirect) || Array.isArray(redirect))) {
        setLoginRedirect(redirect);
      }

      if (Array.isArray(token) || Array.isArray(rid)) {
        return;
      }

      if (!isNil(token) || !isNil(rid)) {
        let response: AuthPayload;

        if (!isNil(token) && token.length > 0) {
          setTokenOrRidLoginLoading(true);
          response = await loginWithToken({ token });

          await handleAuthResponse({
            response,
            isFirstLogin: false,
            loginAction: LoginAction.MAGIC_LINK,
          });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);

            handleMagicLoginError(response.error);
          }

          return;
        }

        if (!isNil(rid) && rid.length > 0) {
          setTokenOrRidLoginLoading(true);

          response = await confirmAndAuthUser({ rid });
          await handleAuthResponse({
            response,
            isFirstLogin: true,
            loginAction: LoginAction.EMAIL_CONFIRMATION,
          });

          if (response.status === AuthStatus.ERROR) {
            setTokenOrRidLoginLoading(false);

            handleMagicLoginError(response.error);
          }

          return;
        }

        setLoginError(translate('login.errors.default_login_error'));
      }
    })();
  }, []);

  const onSubmit = async (values: FormValues) => {
    const { emailAddress, password } = values;

    if (!emailAddress || !password) {
      return;
    }

    setLoading(true);
    setLoginError('');
    setMagicLoginError(undefined);

    const { login } = props;
    const loginVariables: LoginVariables = {
      emailAddress,
      groupId,
      password,
    };

    const response = await login(loginVariables);

    await handleAuthResponse({
      response,
      isFirstLogin: false,
      loginAction: LoginAction.DIRECT_LOGIN,
    });
  };

  const onMagicLinkClicked = () => {
    tracking.track('magic-link-clicked', { subdomain });
    history.push(RoutePath.MagicLogin);
  };

  const magicLinkButtonContents = (
    <MagicLinkButtonContents>
      <WandIcon />
      <div>{translate('login.magic_link')}</div>
    </MagicLinkButtonContents>
  );

  return (
    <>
      <Helmet
        meta={[
          {
            name: `robots`,
            content: 'noindex',
          },
        ]}
      />
      <AuthWrapper subdomain={groupName} newDesign={true}>
        <NewAuthWrapperBlock>
          {tokenOrRidLoginLoading || subdomainInfoLoading ? (
            <Container data-testid="loading-indicator">
              <Loader data-testid="loading-indicator" />
            </Container>
          ) : (
            <>
              <AuthWrapperTitle>{translate('login.title')}</AuthWrapperTitle>
              <Formik
                initialValues={{ emailAddress: '', password: '' }}
                onSubmit={onSubmit}
                validate={({ emailAddress, password }) => {
                  if (!emailAddress || !password) {
                    return {
                      emailAddress: !emailAddress,
                      password: !password,
                    };
                  }

                  const emailAddressError = validateEmail(emailAddress);
                  if (emailAddressError) {
                    return { emailAddress: emailAddressError };
                  }

                  return {};
                }}
              >
                {({
                  isValid,
                  touched,
                  setTouched,
                  setFieldValue,
                  errors,
                  values,
                }) => (
                  <StyledForm>
                    <InputContainer>
                      <TextInput
                        additionalText={{
                          label: translate('login.email_field.label'),
                        }}
                        id="emailAddress"
                        name="emailAddress"
                        type="email"
                        placeholder={''}
                        aria-label={translate(
                          'login.email_field.input_field_label',
                        )}
                        errorText={
                          errors.emailAddress && touched.emailAddress
                            ? errors.emailAddress
                            : undefined
                        }
                        data-testid="email-input"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('emailAddress', e.target.value);
                        }}
                        onBlur={() => setTouched({ emailAddress: true })}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setTouched({ emailAddress: true });
                          }
                        }}
                      />
                    </InputContainer>
                    <InputContainer>
                      <SecureTextInput
                        additionalText={{
                          label: translate('login.password_field.label'),
                        }}
                        id="password-input"
                        name="password"
                        type="password"
                        data-testid="password-input"
                        onChange={e => {
                          setFieldValue('password', e.target.value);
                        }}
                        placeholder={translate(
                          'login.password_field.placeholder_text',
                        )}
                        a11yLabels={{
                          contentsVisibleAlert: translate(
                            'login.password_field.contents_visible_alert',
                          ),
                          contentsHiddenAlert: translate(
                            'login.password_field.contents_hidden_alert',
                          ),
                          toggleButton: translate(
                            'login.password_field.toggle_button',
                          ),
                        }}
                        validityRequirements={[]}
                      />
                    </InputContainer>
                    <PasswordLinkContainer>
                      <ForgotPasswordLink
                        to={RoutePath.ForgotPassword}
                        onClick={() => {
                          tracking.track('forgot-password-clicked', {
                            subdomain,
                          });
                        }}
                        data-testid="forgotten-password-link"
                      >
                        {translate('login.password_field.forgot_password')}
                      </ForgotPasswordLink>
                    </PasswordLinkContainer>
                    {!isMSTeams() ? (
                      <MagicLinkButton
                        type="button"
                        onClick={onMagicLinkClicked}
                        label={magicLinkButtonContents as ReactNode}
                      />
                    ) : null}
                    {group?.authType === AuthTypeEnum.MIXED_MODE && (
                      <StyledSSOButton
                        type="button"
                        onClick={() => {
                          history.push(RoutePath.LoginWithSSO);
                        }}
                        label={
                          ssoProviderName
                            ? translate(
                                'sso.continue_button.sso_provider_label',
                                {
                                  sso_provider: ssoProviderName,
                                },
                              )
                            : translate('sso.continue_button.default_label')
                        }
                      />
                    )}
                    <StyledSubmitButton
                      data-testid="sign-in-button"
                      label={translate('login.submit_button.label')}
                      disabled={!isValid}
                      loading={loading}
                      type="submit"
                      onClick={() => {
                        tracking.track('login-button-clicked', {
                          subdomain,
                        });
                        onSubmit({
                          emailAddress: values.emailAddress,
                          password: values.password,
                        });
                      }}
                    />
                    {Boolean(loginError) && !magicLoginError ? (
                      <Error errorText={loginError} showIcon />
                    ) : null}
                    {magicLoginError ? (
                      <Error errorText={magicLoginError} showIcon />
                    ) : null}
                  </StyledForm>
                )}
              </Formik>
            </>
          )}
        </NewAuthWrapperBlock>
      </AuthWrapper>
    </>
  );
}

export default compose<LoginViaUsernamePasswordProps, RouteComponentProps>(
  withApollo,
  withFeatureFlagUserContext,
  withLogin,
  withLoginWithToken,
  withConfirmAndAuthUser,
  withUpdateUser,
)(LoginViaUsernamePassword);
