import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EmailOnlyFormFields } from 'Shared/Form/Formik';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import getSubdomainFromUrl from '../../../utils/getSubdomainFromUrl';
import {
  AuthWrapper,
  AuthWrapperContent,
  AuthWrapperSubtitle,
  AuthWrapperTitle,
  NewAuthWrapperBlock,
} from '../../AuthWrapper';
import { useGroupId } from '../../useGroupId';
import { useSubdomainInfo } from '../useSubdomainInfo';
import EmailInputForm from './EmailInputForm';

type FormFields = EmailOnlyFormFields;

export const SEND_PASSWORD_RESET_MUTATION = gql`
  mutation SendPasswordReset($email: String!, $groupId: String!) {
    sendPasswordReset(email: $email, groupId: $groupId)
  }
`;

const ForgotPasswordForm = () => {
  const history = useHistory();
  const subdomain = getSubdomainFromUrl();
  const { groupName } = useSubdomainInfo({ subdomain });
  const { groupId } = useGroupId({ subdomain });
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');
  const { t: sharedTranslate } = useTranslation('shared', {
    keyPrefix: 'errors',
  });

  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'login.forms.forgot_password',
  });

  useEffect(() => {
    tracking.track('password-reset-load', { subdomain });
  }, [subdomain]);

  const [sendPasswordReset, { loading }] = useMutation(
    SEND_PASSWORD_RESET_MUTATION,
    {
      onCompleted: () => {
        tracking.track('password-reset', { subdomain });

        history.push(RoutePath.ForgotPasswordEmailConfirmation, {
          email: formikRef?.current?.state.values.emailInput,
          pathTo: RoutePath.ForgotPassword,
        });
      },
      onError: err => {
        setError(
          err.message.toLowerCase().indexOf('rate limit reached') >= 0
            ? translate('errors.rate_limit_exceeded')
            : sharedTranslate('messages.failed'),
        );
      },
    },
  );

  const onSubmit = async (values: FormFields) =>
    sendPasswordReset({
      variables: {
        email: values.emailInput,
        groupId,
      },
    });

  const emailLabel = translate('email_label');
  const emailPlaceholder = translate('email_placeholder');
  const submitLabel = translate('submit_button.label');

  return (
    <AuthWrapper
      subdomain={groupName}
      newDesign
      showCreateAccountButton={false}
    >
      <NewAuthWrapperBlock data-testid="forgot-password-form">
        <AuthWrapperTitle data-testid="form-title">
          {translate('title')}
        </AuthWrapperTitle>
        <AuthWrapperSubtitle data-testid="form-subtitle">
          {translate('subtitle')}
        </AuthWrapperSubtitle>
        <AuthWrapperContent>
          <EmailInputForm
            formikRef={formikRef}
            inputLabel={emailLabel}
            placeholder={emailPlaceholder}
            submitLabel={submitLabel}
            onSubmit={onSubmit}
            loading={loading}
            setError={setError}
            error={error}
          ></EmailInputForm>
        </AuthWrapperContent>
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
};
export default ForgotPasswordForm;
