import RoutePath from 'App/RoutePath';
import {
  AuthWrapper,
  NewAuthWrapperBlock,
  AuthWrapperTitle,
} from 'LoggedOut/AuthWrapper';

import { EmailOnlyFormFields } from 'Shared/Form/Formik';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { isMSTeams } from 'utils/MSTeams';
import { AuthTypeEnum } from '__generated__/globalTypes';
import { useHistory } from 'react-router-dom';
import EmailInputForm from 'LoggedOut/SignUp/Forms/EmailInputForm';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  getOrganisationsByEmailDomain,
  getOrganisationsByEmailDomain_organisationDomainsByEmailDomain as Organisation,
} from './__generated__/getOrganisationsByEmailDomain';
import { SelectOrganisation } from './SelectOrganisation';

export const FormContent = styled.div`
  padding-top: ${rem(16)};
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
`;

export const FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY = gql`
  query getOrganisationsByEmailDomain($email: String!) {
    organisationDomainsByEmailDomain(email: $email) {
      primarySubDomain
      id
      name
      active
      authType
    }
  }
`;

type FormFields = EmailOnlyFormFields;

export const StartV2 = () => {
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'start_v2',
  });
  const newStartRouteEnabled = useFeatureFlag(
    FEATURE_FLAGS.NEW_START_ROUTE_ENABLED,
  );
  const [showSelectOrg, setShowMultiOrgSelect] = useState(false);
  const history = useHistory();
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');

  const getRedirectUrl = (subdomain: string, authType?: AuthTypeEnum) => {
    let redirectPath;

    if (isMSTeams()) {
      localStorage.setItem('subdomain', subdomain);
    }

    if (authType === AuthTypeEnum.AUTH0_SSO) {
      redirectPath = RoutePath.LoginWithSSO;
    } else {
      redirectPath = RoutePath.LoginWithCredentials;
    }

    return `${window.location.protocol}//${subdomain}.${process.env.REACT_APP_DOMAIN}${redirectPath}`;
  };

  const [findOrganisationByEmailDomain, { data: organisationsData, loading }] =
    useLazyQuery<getOrganisationsByEmailDomain>(
      FIND_ORGANISATON_BY_EMAIL_DOMAIN_QUERY,
      {
        onCompleted: data => {
          const organisations: Organisation[] =
            data.organisationDomainsByEmailDomain?.filter(
              org => org !== null && org.active,
            ) as Organisation[];

          if (!organisations || organisations.length === 0) {
            history.push(RoutePath.SignInToYourOrganisation);
          }

          const moreThanOneActiveOrg = organisations.length > 1;

          if (moreThanOneActiveOrg) {
            setShowMultiOrgSelect(true);

            return;
          }

          const matchedOrganisation = organisations[0];

          window.location.assign(
            getRedirectUrl(
              matchedOrganisation.primarySubDomain,
              matchedOrganisation.authType,
            ),
          );
        },
      },
    );

  if (!newStartRouteEnabled) {
    history.push(RoutePath.StartSplitScreen);
  }

  const organisations =
    organisationsData?.organisationDomainsByEmailDomain?.filter(
      (org): org is Organisation => org !== null,
    );

  const onSubmit = async (values: FormFields) =>
    findOrganisationByEmailDomain({
      variables: { email: values.emailInput },
    });

  const emailLabel = translate('email_field.label');
  const emailPlaceholder = '';
  const submitLabel = translate('submit_button.label');

  return (
    <AuthWrapper newDesign>
      <NewAuthWrapperBlock>
        {showSelectOrg && organisations ? (
          <SelectOrganisation
            organisations={organisations}
            getRedirectUrl={getRedirectUrl}
          />
        ) : (
          <>
            <AuthWrapperTitle>{translate('title')}</AuthWrapperTitle>
            <FormContent>
              <EmailInputForm
                formikRef={formikRef}
                inputLabel={emailLabel}
                placeholder={emailPlaceholder}
                submitLabel={submitLabel}
                onSubmit={onSubmit}
                loading={loading}
                setError={setError}
                error={error}
              ></EmailInputForm>
            </FormContent>
          </>
        )}
      </NewAuthWrapperBlock>
    </AuthWrapper>
  );
};
